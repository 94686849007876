export const dataType = [
  { 'id': 'PILOTO', 'nome': 'Relatório por piloto' },
  { 'id': 'FOCO_FAZENDA', 'nome': 'Relatório por fazenda'},
]

export const dataTypeActivities = [
  { 'id': 0, 'nome': 'Todos' },
  { 'id': 1, 'nome': 'Líquidos' },
  { 'id': 2, 'nome': 'Sólidos' }
]

export const dataPilotStatus = [
  { 'id': 'TODOS', 'nome': 'Todos'},
  { 'id': 'ATIVOS', 'nome': 'Apenas pilotos ativos'}
]