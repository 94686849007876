export class DefaultModel {
  token = null;

  constructor(token) {
    this.token = token;
  }

  converter_obj_query_string(obj) {
    return new URLSearchParams(obj).toString();
  }

}