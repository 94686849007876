
import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, TextField, Typography, Button, InputAdornment } from "@mui/material"

import { getLocation, ErrorToStringFix, showLoading } from "../../../Services/globalFunction";

import { User } from "../../../Models/User";
import { Culture } from "../../../Models/Essential/Culture";
import { Property } from "../../../Models/Organization/Property";
import { Machinery } from "../../../Models/Machinery/Machinery";
import { Activity } from "../../../Models/Activities/Activities";
import { Contractor } from '../../../Models/Organization/Contractor';

import Alertavel from "../../../Components/Alertavel";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import "../../../App.css";

const WorkedActivities = ({ module }) => {
  const [title, setTitle] = useState("Atividades de Pulverização");

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [contentModal, setContentModal] = useState("");
  const [contentAlert, setContentAlert] = useState("");

  const [btnReset, setBtnReset] = useState('');

  // Dados a serem carregados nos selects
  const [dataField, setDataField] = useState([]);
  const [dataCulture, setDataCulture] = useState([]);
  const [dataApplication, setDataApplication] = useState([]);
  const [dataMachinery, setDataMachinery] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataContractor, setDataContractor] = useState([]);

  // Dados Selecionados e ou inseridos nos campos
  const [formData, setFormData] = useState({
    id: "",
    talhao: null,
    maquina: null,
    cultura: null,
    latitude: "",
    longitude: "",
    descricao: "",
    origem: "site",
    aplicacoes: [],
    qtd_hectares: "",
    propriedade: null,
    contratante: null,
    data_atividade: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  const { state } = location;

  const LoadProperty = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0,
      ativa: 'S'
    }

    let property = new Property(token);
    let Properties = await property.SearchProperty(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataProperty(Properties)
  }, [module.modulo, token]);

  const LoadMachinery = useCallback(async (propertyId) => {
    let machinery = new Machinery(token);
    let machineries = await machinery.SearchPropertyMachinery(propertyId).then(response => {
      if (!response.erro) {
        return response.map(item => ({ id: item.id, nome: item.numero + " - " + item.nome }));
      } else {
        setContentAlert("Você não possui permissão para listar os maquinários. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataMachinery(machineries);
  }, [token]);

  const LoadApplication = useCallback(async () => {
    let Application = new Culture(token);
    let Applications = await Application.SearchApplicationType().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as aplicações. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataApplication(Applications);

  }, [token]);

  const LoadCulture = useCallback(async () => {
    let Cultures = new Culture(token);
    let culture = await Cultures.SearchCulture().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as culturas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataCulture(culture);
  }, [token]);

  const LoadField = useCallback(async (propertyId) => {
    let Field = new Property(token);
    let fields = await Field.SearchPropertyField(propertyId).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar os Talhões. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataField(fields);
  }, [token]);

  const LoadContractors = useCallback(async () => {
    let Contractors = new Contractor(token);
    let contractors = await Contractors.SearchContractor().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar os Contratantes. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataContractor(contractors);
  }, [token])

  //Change campos
  const handlePropertyOnChange = async (event) => {
    if (event) {
      setFormData({ ...formData, propriedade: event.id, talhoes: null });
      LoadField(event.id);
      LoadMachinery(event.id);
    }
  }

  const handleApplicationOnChange = (event) => {
    if (event) {
      let updatedApplication = [];

      event.forEach((val, i) => {
        updatedApplication.push({ id: val.id, nome: val.nome });
      });

      setFormData((prevData) => ({ ...prevData, aplicacoes: updatedApplication }));
    }
  }

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'atividade-trabalhada');
    navigate(`/gestor-atividades${routes.rota}`);

    setTitle("Atividades de Pulverização");

    setFormData({
      id: "",
      talhao: null,
      maquina: null,
      cultura: null,
      latitude: "",
      longitude: "",
      descricao: "",
      origem: "site",
      aplicacoes: [],
      qtd_hectares: "",
      propriedade: null,
      contratante: null,
      data_atividade: "",
    });

    setBtnReset('');
  }, [module.rotas, navigate]);

  const validateForm = () => {
    const requiredFields = [
      'talhao',
      'maquina',
      'cultura',
      'origem',
      'aplicacoes',
      'propriedade',
      'qtd_hectares',
      'data_atividade'
    ];

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field]);

    if (isAnyFieldInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    return null;
  };

  const showSuccessAlert = (message) => {
    setOpenModal(true);
    setContentModal(
      <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Alertavel title="" type="success" open={true} content={message} />
        </Grid>
      </Grid>
    );
  };

  const saveOnClick = async (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    try {
      const position = await getLocation();
      formData.latitude = position.coords.latitude.toString();
      formData.longitude = position.coords.longitude.toString();
    } catch (error) {
      console.error("Erro ao obter geolocalização:", error);
    }

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    showLoading(true);
    var result = null
    if (dataId) {
      const listApplication = formData.aplicacoes.map((val) => val.id);
      const dataToSubmit = { ...formData, aplicacoes: listApplication };

      const Activities = new Activity(token);
      result = await Activities.UpdateWorkedActivities(dataId, dataToSubmit)
    } else {
      const listApplication = formData.aplicacoes.map((val) => val.id);
      delete formData.id;
      const dataToSubmit = { ...formData, aplicacoes: listApplication };

      const Activities = new Activity(token);
      result = await Activities.AddWorkedActivities(dataToSubmit);
    }
    showLoading(false);

    if (result.erro !== undefined) {
      let mensagem = ErrorToStringFix(result.erro)
      setContentAlert(mensagem);
      setOpenAlert(true);
    } else {
      showSuccessAlert("Dados Atualizados com sucesso");
    }

  }

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  useEffect(() => {
    LoadProperty();
    LoadCulture();
    LoadApplication();
    LoadContractors();
  }, [token, LoadProperty, LoadApplication, LoadCulture, LoadContractors]);

  useEffect(() => {
    const { tipo, data: dataEdit } = state || {};

    const loadData = async () => {
      const { id, fazenda_id, talhao_id, cultura_id, maquina_id, descricao, contratante_id, qtd_hectares, data_atividade, aplicacoes } = dataEdit;

      const [day, month, year] = data_atividade.split("/");
      const convertedData = `${year}-${month}-${day}`;
      let application = [];

      setTitle("Editando Atividade de Pulverização - #" + id);

      setBtnReset(
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container mb={2}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Button className="successBtn" variant="contained" fullWidth onClick={handleResetPage}>
              Atividade de Pulverização
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );

      LoadContractors();
      LoadField(fazenda_id);
      LoadMachinery(fazenda_id);

      aplicacoes.forEach((val, i) => {
        application.push({ id: val.id, nome: val.nome });
      });

      setFormData({
        origem: "Site",
        id: id,
        aplicacoes: application,
        descricao: descricao,
        qtd_hectares: qtd_hectares,
        talhao: parseInt(talhao_id),
        data_atividade: convertedData,
        cultura: parseInt(cultura_id),
        maquina: parseInt(maquina_id),
        propriedade: parseInt(fazenda_id),
        contratante: parseInt(contratante_id),
      });

    }

    if (tipo === "edicao" && dataEdit) {
      loadData();
    } else {
      handleResetPage();
    }
  }, [state, LoadField, handleResetPage, LoadContractors, LoadMachinery]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Gestor de Atividades</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      {btnReset}

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

        <Grid container>
          <Typography variant="h6" className="titles">Datas</Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>

            <TextField
              type="date"
              id="data_atividade"
              value={formData.data_atividade}
              label="Data da Atividade"
              onChange={(event) => setFormData({ ...formData, data_atividade: event.target.value })}
              InputLabelProps={{ shrink: true, required: true }}
            />

          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Propriedade e Equipamento</Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              id="contratante"
              label="Contratante"
              isMultiple={false}
              required={false}
              disabled={false}
              data={dataContractor}
              value={formData.contratante}
              onChange={(event) => setFormData({ ...formData, contratante: event ? event.id : null })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              id="propriedade"
              label="Propriedade"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataProperty}
              value={formData.propriedade}
              onChange={handlePropertyOnChange}
            />
          </Grid>

        </Grid>

        <Grid container spacing={1} >

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              id="talhao"
              label="Talhão"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataField}
              value={formData.talhao}
              onChange={(event) => setFormData({ ...formData, talhao: event ? event.id : null })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              id="maquinario"
              label="Maquinário"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataMachinery}
              value={formData.maquina}
              onChange={(event) => setFormData({ ...formData, maquina: event ? event.id : null })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Aplicação</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item lg={5} md={12} sm={12} xs={12}>
            <AutocompleteSelect
              id="tipo_aplicacao"
              label="Tipo de Aplicação"
              isMultiple={true}
              required={true}
              disabled={false}
              data={dataApplication}
              value={formData.aplicacoes}
              onChange={handleApplicationOnChange}
            />
          </Grid>

          <Grid item lg={5} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              id="cultura"
              label="Cultura"
              isMultiple={false}
              required={true}
              disabled={false}
              data={dataCulture}
              value={formData.cultura}
              onChange={(event) => setFormData({ ...formData, cultura: event ? event.id : null })}
            />
          </Grid>

          <Grid item lg={2} md={6} sm={12} xs={12}>
            <TextField
              id="hectare"
              type="number"
              label="Qtd. Hectares"
              value={formData.qtd_hectares}
              InputProps={{
                required: true,
                endAdornment: <InputAdornment position="start">ha</InputAdornment>,
              }}
              onChange={(event) => setFormData({ ...formData, qtd_hectares: event.target.value })}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Grid container>
          <Typography variant="h6" className="titles">Descrição</Typography>
        </Grid>

        <Grid container spacing={2}>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              multiline
              rows={4}
              id="descricao"
              label="Descreva a Atividade"
              value={formData.descricao}
              onChange={(event) => setFormData({ ...formData, descricao: event.target.value })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1} textAlign="center">
        <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Gestor de Atividades"
      />

    </Grid>
  );
}

export default WorkedActivities;