import { DefaultModel } from "../DefaultModel";
import { searchPilot, searchSubsidiaries } from "../../Services/API/api";

export class Company extends DefaultModel {

  async SearchSubsidiaries(companyId, parameters) {
    const response = await searchSubsidiaries(this.token, companyId, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchPilot(companyId, pilotStatus) {
    const response = await searchPilot(this.token, companyId, pilotStatus);
    return (response) || [];
  }

}