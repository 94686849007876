
export const dataMovements = [
  { id: 1, nome: "Aquisição - Fornecedor para CD", tipo_origem: "FORNECEDOR", tipo_destino: "FILIAL" },
  { id: 2, nome: "Devolução - CD para Fornecedor", tipo_origem: "FILIAL", tipo_destino: "FORNECEDOR" },
  { id: 3, nome: "Distribuição - CD para Fazenda", tipo_origem: "FILIAL", tipo_destino: "FAZENDA" },
  { id: 4, nome: "Reposição - Fazenda para CD", tipo_origem: "FAZENDA", tipo_destino: "FILIAL" },
  { id: 5, nome: "Transferência - Fazenda para Fazenda", tipo_origem: "FAZENDA", tipo_destino: "FAZENDA" },
  { id: 6, nome: "Aplicação - Fazenda para Campo", tipo_origem: "FAZENDA", tipo_destino: "USO" },
  { id: 7, nome: "Retorno - Campo para Fazenda", tipo_origem: "USO", tipo_destino: "FAZENDA" }
];

export const dataUnit = [
  { id: "L", nome: "Litro (L)" },
  { id: "GRAMA", nome: "Grama (g)" },
  { id: "ML", nome: "Mililitro (ml)" },
  { id: "TON", nome: "Tonelada (t)" },
  { id: "MG", nome: "Miligrama (mg)" },
  { id: "KG", nome: "Quilograma (kg)" },
];