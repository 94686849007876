import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Home from './Pages/Home';
import Login from './Pages/Login';
import Finances from './Pages/Finances';
import Inventory from './Pages/Inventory';
import Activities from './Pages/Activities';
import Properties from './Pages/Properties';
import Calculators from './Pages/Calculators';
import Weather from './Pages/WeatherForecast';
import AgronomicPrescription from './Pages/AgronomicPrescription';

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/estoque/*" element={<Inventory />} />
          <Route exact path="/financeiro/*" element={<Finances />} />
          <Route exact path="/propriedades/*" element={<Properties />} />
          <Route exact path="/calculadoras/*" element={<Calculators />} />
          <Route exact path="/previsao-tempo" element={<Weather />} />
          <Route exact path="/gestor-atividades/*" element={<Activities />} />
          <Route exact path="/receituario-agronomico/*" element={<AgronomicPrescription />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}