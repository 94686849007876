import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, TablePagination } from '@mui/material';

export default function WeatherTable({ headers, rows, actions, length, maxHeight, columnColors }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: maxHeight || 650 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((val, index) => (
                <TableCell key={index}>{val}</TableCell>
              ))}
              {actions !== null ? <TableCell>Ações</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={row.id}>
                {Object.entries(row).map(([key, value], index) => {
                  if (key !== 'id' && key !== 'status') {
                    return (<TableCell key={index} sx={{ color: columnColors?.[index] || 'inherit' }}>{value}</TableCell>);
                  }
                  return null;
                })}
                {actions !== null ? (
                  <TableCell key={`action-${row.id}`}>
                    {actions(row.id, row.status)}
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}