import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import "./css/index.css";

export default function AutocompleteSelect({ id, label, value, onChange, data, disabled, required, isMultiple, variant}) {
  const [selectedOption, setSelectedOption] = useState(isMultiple ? [] : null);

  useEffect(() => {
    if (data) {
      if (isMultiple) {
        setSelectedOption(value || []);
      } else {
        const initialValue = value ? data.find(option => option.id === value) : null;
        setSelectedOption(initialValue);
      }
    }
  }, [data, isMultiple, value]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (onChange) {
      onChange(newValue || (isMultiple ? [] : null));
    }
  };

  return (
    <Autocomplete
      readOnly={disabled}
      multiple={isMultiple}
      id={id}
      options={data}
      onChange={handleChange}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
      getOptionLabel={(option) => option.nome}
      renderInput={(params) => (
        <TextField
          {...params}
          label={required ? `${label} *` : label}
          variant={variant || "outlined"}
        />
      )}
      noOptionsText="Nenhuma opção encontrada"
    />
  );
}
