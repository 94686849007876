import { addCategories, deleteCategories, searchCategories, searchCategory, updateCategory } from "../../Services/API/api";
import { DefaultModel } from "../DefaultModel";

export class CategoryFinances extends DefaultModel {

  async SaveCategory(parameters) {
    const response = await addCategories(this.token, JSON.stringify(parameters));
    return (response) || [];
  }

  async SearchCategories(parameters) {
    const response = await searchCategories(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchCategory(category_id) {
    const response = await searchCategory(this.token, category_id);
    return (response) || [];
  }

  async UpdateCategory(category_id, parameters) {
    const response = await updateCategory(this.token, category_id, JSON.stringify(parameters));
    return (response) || [];
  }

  async DeleteCategory(category_id) {
    const response = await deleteCategories(this.token, category_id);
    return (response) || [];
  }
}