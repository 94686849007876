import { DefaultModel } from "../DefaultModel";
import {
  addNotWorkedActivities,
  addWorkedActivities,
  deleteNotWorkedActivities,
  deleteWorkedActivities,
  generateActivitiesReport,
  searchActivities,
  searchJustifications,
  searchNotWorkedActivities,
  searchWorkedActivities,
  updateNotWorkedActivities,
  updateWorkedActivities,
  generateActivitiesDashboard
} from "../../Services/API/api";

export class Activity extends DefaultModel {
  async SearchJustifications() {
    const response = await searchJustifications(this.token);
    return (response) || [];
  }

  async SearchActivities(parameters) {
    const response = await searchActivities(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchWorkedActivities(id) {
    const response = await searchWorkedActivities(this.token, id);
    return (response) || [];
  }

  async SearchNotWorkedActivities(id) {
    const response = await searchNotWorkedActivities(this.token, id);
    return (response) || [];
  }

  async UpdateWorkedActivities(id, parameters) {
    const response = await updateWorkedActivities(this.token, id, JSON.stringify(parameters));
    return (response) || [];
  }

  async UpdateNotWorkedActivities(id, parameters) {
    const response = await updateNotWorkedActivities(this.token, id, JSON.stringify(parameters));
    return (response) || [];
  }

  async AddWorkedActivities(parameters) {
    const response = await addWorkedActivities(this.token, JSON.stringify(parameters));
    return response;
  }

  async AddNotWorkedActivities(parameters) {
    const response = await addNotWorkedActivities(this.token, JSON.stringify(parameters));
    return response;
  }

  async DeleteWorkedActivities(id) {
    const response = await deleteWorkedActivities(this.token, id);
    return (response) || [];
  }

  async DeleteNotWorkedActivities(id) {
    const response = await deleteNotWorkedActivities(this.token, id);
    return (response) || [];
  }

  async GenerateActivitiesReport(parameters) {
    const response = await generateActivitiesReport(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async GenerateActivitiesDashboard(parameters) {
    const response = await generateActivitiesDashboard(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

}