import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ProductAutocomplete({ id, label, value, onChange, data, disabled, isReset }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (isReset) {
      setOptions([]);
      setSelectedOption(null);
    }

    if (data) {
      setOptions(data);

      if (value) {
        let initialValue = "";

        if (value.variacao !== undefined) {
          initialValue = data.find(option => option.item_variacao === value.variacao);
        } else {
          initialValue = data.find(option => option.id === value.id);
        }

        setSelectedOption(initialValue);

      }
    }


  }, [data, value, isReset]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (onChange) {
      onChange(newValue || null);
    }
  };

  return (
    <Autocomplete
      id={id}
      options={options}
      onChange={handleChange}
      value={selectedOption || null}
      getOptionLabel={(option) => option.nome}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          disabled={disabled}
        />
      )}
      noOptionsText="Nenhuma opção encontrada"
    />
  );
}