import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../../App.css";

const Plantability = () => {
  const [title] = useState("Calculadora de Semeadura");

  const [formData, setFormData] = useState({
    populacaoDesejada: "",
    taxaGerminacao: "",
    pms: "",
    espacamentoLinhas: "",
  });

  const handleInputChange = (event, min, max) => {
    const { name, value } = event.target;
    const sanitizedValue = value === "" ? "" : Math.max(min, Math.min(max, parseFloat(value)));
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const sementesPorMetroQuadrado = () => {
    const { populacaoDesejada, taxaGerminacao } = formData;
    if (!populacaoDesejada || !taxaGerminacao) return "";
    return ((populacaoDesejada * 100) / taxaGerminacao).toFixed(2);
  };

  const sementesPorMetroLinear = () => {
    const { espacamentoLinhas } = formData;
    const spmq = sementesPorMetroQuadrado();
    if (!espacamentoLinhas || !spmq) return "";
    return (spmq / (100 / espacamentoLinhas)).toFixed(2);
  };

  const quilogramasPorHectare = () => {
    const { pms } = formData;
    const spmq = sementesPorMetroQuadrado();
    if (!spmq || !pms) return "";
    return ((spmq * 10 * pms) / 1000).toFixed(2);
  };

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Calculadoras - Semeadura</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h4">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>
            <TextField
              name="populacaoDesejada"
              label="Plantas por metro quadrado"
              type="number"
              value={formData.populacaoDesejada}
              inputProps={{ min: 1, max: 100 }}
              onChange={(e) => handleInputChange(e, 1, 100)}
              fullWidth
              margin="normal"
            />
            <TextField
              name="taxaGerminacao"
              label="Taxa de germinação (percentual %)"
              type="number"
              value={formData.taxaGerminacao}
              inputProps={{ min: 1, max: 100 }}
              onChange={(e) => handleInputChange(e, 1, 100)}
              fullWidth
              margin="normal"
            />
            <TextField
              name="pms"
              label="PMS - Peso de mil sementes (gramas)"
              type="number"
              value={formData.pms}
              inputProps={{ min: 1, max: 1000 }}
              onChange={(e) => handleInputChange(e, 1, 1000)}
              fullWidth
              margin="normal"
            />
            <TextField
              name="espacamentoLinhas"
              label="Espaçamento entre linhas (centímetros)"
              type="number"
              value={formData.espacamentoLinhas}
              inputProps={{ min: 1, max: 100 }}
              onChange={(e) => handleInputChange(e, 1, 100)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quilos de sementes por ha."
              type="number"
              value={quilogramasPorHectare()}
              InputProps={{
                readOnly: true,
                disabled: true,
                endAdornment: "kg/ha",
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sementes por m²"
              type="number"
              value={sementesPorMetroQuadrado()}
              InputProps={{
                readOnly: true,
                disabled: true,
                endAdornment: "/m²",
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sementes por metro linear"
              type="number"
              value={sementesPorMetroLinear()}
              InputProps={{
                readOnly: true,
                disabled: true,
                endAdornment: "/m",
              }}
              fullWidth
              margin="normal"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Plantability;