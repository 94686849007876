import { searchCities, searchState } from "../../Services/API/api";
import { DefaultModel } from "../DefaultModel";

export class City extends DefaultModel {
  async SearchState() {
    const response = await searchState(this.token);
    return (response) || [];
  }

  async SearchCities(uf) {
    const response = await searchCities(uf);
    return (response) || [];
  }
}