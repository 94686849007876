import { DefaultModel } from "../DefaultModel";
import { searchProvider } from "../../Services/API/api";

export class Provider extends DefaultModel {

  async SearchProvider() {
    const response = await searchProvider(this.token);
    return (response) || [];
  }

}