import { DefaultModel } from "../DefaultModel";
import { checkInventory} from "../../Services/API/api";

export class Inventory extends DefaultModel {

  async CheckInventory(parameters) {
    const response = await checkInventory(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

}