import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleCheck, faCircleXmark, faFilter } from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";
import Alertavel from "../../../Components/Alertavel";
import { User } from "../../../Models/User";
import { Property } from "../../../Models/Organization/Property";
import "../css/index.css";

const ListPropertiesOrFields = ({ module }) => {
  const [properties, setProperties] = useState([]);
  const [groupedFields, setGroupedFields] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState("");
  const [dataProperty, setDataProperty] = useState([]);
  const [formData, setFormData] = useState({ fazendas: [], viewType: "properties" });

  const user = User.searchUser();
  const token = user.token;

  const viewOptions = [
    { id: "properties", nome: "Propriedades" },
    { id: "fields", nome: "Talhões" },
  ];

  const LoadFarm = useCallback(async () => {
    const parameters = { modulo: module.modulo ?? 0 };
    let Farm = new Property(token);
    let Properties = await Farm.SearchProperty(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setProperties(Properties);
    setDataProperty(Properties);
  }, [module.modulo, token]);

  const LoadFields = async (propertyIds) => {
    let Field = new Property(token);
    await Field.SearchPropertyField(propertyIds).then(response => {
      if (response.length > 0) {
        const grouped = response.reduce((acc, item) => {
          if (!acc[item.fazenda_nome]) acc[item.fazenda_nome] = [];
          acc[item.fazenda_nome].push({
            id: item.id,
            nome: item.nome,
            fazenda_nome: item.fazenda_nome,
            ativa: item.ativa === 'S' ? 'S' : 'N',
          });
          return acc;
        }, {});
        setGroupedFields(grouped);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os Talhões. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        setGroupedFields({});
      }
    });
  };

  const handleViewChange = (event) => {
    setGroupedFields({});
    setFormData((prevData) => ({ ...prevData, fazendas: [] }));
    setFormData((prevData) => ({ ...prevData, viewType: event.id }));
  };

  const handleFarmChange = (event) => {
    if (event) {
      let updateFarms = [];
      event.forEach((val) => updateFarms.push({ id: val.id, nome: val.nome }));
      setFormData((prevData) => ({ ...prevData, fazendas: updateFarms }));
    }
  };

  const filterClick = async () => {
    setOpenAlert(false);
    if (formData.fazendas.length > 0) {
      setGroupedFields({});
      const propertyIds = formData.fazendas.map(fazenda => fazenda.id).join(',');
      await LoadFields(propertyIds);
    } else {
      setContentAlert("É necessário informar a(s) fazenda(s) para fazer a filtragem.");
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    LoadFarm();
  }, [LoadFarm]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Lista de Propriedades ou Talhões</title>
      </Helmet>

      <Grid item mt={4} mb={4} lg={12} md={12} sm={12} xs={12} textAlign="center">
        <Typography className="titles-pages" variant="h4">
          {formData.viewType === "properties" ? "LISTA DE PROPRIEDADES" : "LISTA DE TALHÕES"}
        </Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Accordion defaultExpanded id="panel-header" aria-controls="panel-content">
          <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
            <Grid container>
              <Typography className="titles" variant="h6">Selecione os dados abaixo:</Typography>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Grid item lg={12} md={4} sm={4} xs={12}>
                <AutocompleteSelect
                  id="view-type"
                  label="Exibição"
                  isMultiple={false}
                  required={true}
                  disabled={false}
                  data={viewOptions}
                  value={formData.viewType}
                  onChange={handleViewChange}
                />
              </Grid>

              {formData.viewType === "fields" && (
                <>
                  <Grid item lg={10} md={4} sm={4} xs={12}>
                    <AutocompleteSelect
                      id="fazenda"
                      label="Fazenda"
                      isMultiple={true}
                      required={true}
                      disabled={false}
                      data={dataProperty}
                      value={formData.fazenda}
                      onChange={handleFarmChange}
                    />
                  </Grid>

                  <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                    <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}>
                      <FontAwesomeIcon className="font-icons" icon={faFilter} /> Filtrar
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container justifyContent="center" mt={1}>
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12}>
        <Grid container>
          {formData.viewType === "properties" && properties.map((propriedade) => (
            <Grid item lg={2} md={12} sm={12} xs={12} mb={3} key={propriedade.id} margin={0.5}>
              <Card className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Grid container>
                      <Grid item xs={12}>
                        <span>{propriedade.ativa === 'S' ? <FontAwesomeIcon color="#009951" icon={faCircleCheck} /> : <FontAwesomeIcon color="red" icon={faCircleXmark} />}</span>
                        <Typography className="titles" variant="p" ml={1}>{propriedade.nome}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <Card className="flip-card-back">
                    <Grid container>
                      <Grid className="gridCity" item xs={12}>
                        <Typography className="titles" variant="p">{propriedade.cidade_nome}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </Card>
            </Grid>
          ))}
          {formData.viewType === "fields" && Object.keys(groupedFields).map((fazendaNome) => (
            <Grid item lg={12} md={12} sm={12} xs={12} mb={1} key={fazendaNome}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h6">{fazendaNome}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {groupedFields[fazendaNome].map((field) => (
                      <Grid item key={field.id} lg={4} md={6} sm={12} xs={12}>
                        <Typography>
                          <FontAwesomeIcon color={field.ativa === 'S' ? "#009951" : "red"} icon={field.ativa === 'S' ? faCircleCheck : faCircleXmark} />
                          <span style={{ marginLeft: "8px" }}>{field.nome}</span>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ListPropertiesOrFields;
