  import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../../App.css";

  const SoybeanYield = () => {
    const [title] = useState("Calculadora de Produtividade de Soja");

    const [formData, setFormData] = useState({
      hectares: "",
      plantasPor10m: "",
      espacamentoLinhas: "",
      vagensPor10Plantas: "",
      graosPor50Vagens: "",
      pesoMilGraos: ""
    });

    const handleInputChange = (event, min, max) => {
      const { name, value } = event.target;
      const sanitizedValue = value === "" ? "" : Math.max(min, Math.min(max, parseFloat(value)));
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    };

    const produtividadePorHectare = () => {
      const { plantasPor10m, espacamentoLinhas, vagensPor10Plantas, graosPor50Vagens, pesoMilGraos } = formData;
      if (plantasPor10m && espacamentoLinhas && vagensPor10Plantas && graosPor50Vagens && pesoMilGraos) {
        const plantasPorHectare = (plantasPor10m / (espacamentoLinhas/100));
        const produtividade = (plantasPorHectare * (vagensPor10Plantas/10) * (graosPor50Vagens/50) * (pesoMilGraos/1000)) / 60.000;
        return produtividade.toFixed(2);
      }
      return "";
    };

    const produtividadeTotal = () => {
      const { hectares } = formData;
      const produtividade = produtividadePorHectare();
      if (hectares && produtividade) {
        const produtividadeTotal = produtividade * hectares
        return produtividadeTotal.toFixed(2);
      }
      return "";
    };

    return (
      <Grid container justifyContent="center">
        <Helmet>
          <title>Calculadoras - Produtividade de Soja</title>
        </Helmet>

        <Grid item lg={8} md={12} sm={12} xs={12} mb={2} mt={2} textAlign="center">
          <Typography className="titles-pages" variant="h4">{title}</Typography>
        </Grid>

        <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
          <Card className="card-form">
            <CardContent>
              <TextField
                name="hectares"
                label="Quantidade de Hectares"
                type="number"
                value={formData.hectares}
                inputProps={{ min: 1, max: 100000 }}
                onChange={(e) => handleInputChange(e, 1, 100000)}
                fullWidth
                margin="normal"
              />
              <TextField
                name="plantasPor10m"
                label="Plantas por 10 metros de linha"
                type="number"
                value={formData.plantasPor10m}
                inputProps={{ min: 1, max: 1000 }}
                onChange={(e) => handleInputChange(e, 1, 1000)}
                fullWidth
                margin="normal"
              />
              <TextField
                name="espacamentoLinhas"
                label="Espaçamento entre linhas (centímetros)"
                type="number"
                value={formData.espacamentoLinhas}
                inputProps={{ min: 1, max: 100 }}
                onChange={(e) => handleInputChange(e, 1, 100)}
                fullWidth
                margin="normal"
              />
              <TextField
                name="vagensPor10Plantas"
                label="Vagens em 10 plantas"
                type="number"
                value={formData.vagensPor10Plantas}
                inputProps={{ min: 1, max: 1000 }}
                onChange={(e) => handleInputChange(e, 1, 1000)}
                fullWidth
                margin="normal"
              />
              <TextField
                name="graosPor50Vagens"
                label="Grãos em 50 vagens"
                type="number"
                value={formData.graosPor50Vagens}
                inputProps={{ min: 1, max: 200 }}
                onChange={(e) => handleInputChange(e, 1, 200)}
                fullWidth
                margin="normal"
              />
              <TextField
                name="pesoMilGraos"
                label="Peso de mil grãos (gramas)"
                type="number"
                value={formData.pesoMilGraos}
                inputProps={{ min: 1, max: 500 }}
                onChange={(e) => handleInputChange(e, 1, 500)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Produtividade estimada (sacas / ha)"
                type="number"
                value={produtividadePorHectare()}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  endAdornment: "scs/ha",
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Produtividade total (sacas)"
                type="number"
                value={produtividadeTotal()}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  endAdornment: "scs",
                }}
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  export default SoybeanYield;
