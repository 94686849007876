import { DefaultModel } from "../DefaultModel";
import { searchProperty, searchPropertyInfo, searchPropertyField } from "../../Services/API/api";

export class Property extends DefaultModel {

  async SearchProperty(parameters) {
    const response = await searchProperty(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async SearchPropertyInfo(propertyId) {
    const response = await searchPropertyInfo(this.token, propertyId);
    return (response) || [];
  }

  async SearchPropertyField(propertyId) {
    const response = await searchPropertyField(this.token, propertyId);
    return (response) || [];
  }
}