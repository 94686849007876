import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import ResponsiveDialog from "../Dialog/Dialog";

import "./css/index.css";

export default function ProductCards({ product, isLoggedIn, modules, weatherCardColor }) {
  const [conteudo, setConteudo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const modalClose = () => {
    setOpenModal(false);
    navigate("/");
  };

  const renderCardActionArea = (product) => {
    return (
      <>
        {/*{product
          .filter((item) => item.permission === "propriedades")
          .map((item) => (
            <Grid item key={item.id} lg={12} md={12} sm={12} xs={12} mb={1}>
              <CardActionArea className="btnCard btnCardFarm" component="button" onClick={() => handleClickAction(item)}>
                <Card className="card-farm">
                  <CardContent className="card-farm-content">
                    <Typography className="titles" variant="h5" component="div">
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          ))}*/}

        {product
          .filter((item) => item.permission !== "propriedades")
          .map((item, index) => (
            <Grid key={index} item lg={4} md={6} sm={6} xs={6} display="flex" justifyContent="center">
              <CardActionArea className="btnCardProducts" component="button" onClick={() => handleClickAction(item)}>
                <Card className="card-products">
                  <CardContent>
                    <Grid container display="flex" justifyContent="center">
                      <Grid item xs={12} textAlign="center">
                        <Typography className="titles icon-product">{item.icon}</Typography>
                      </Grid>
                      <Grid item xs={12} mt={1} className="productsGrid" textAlign="center">
                        <Typography className="titles" variant="h6" component="div">{item.title}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          ))}
      </>
    );
  };

  const checkPermission = (item) => {
    return modules.some((element) => element.nome_permissao === item.permission);
  };

  const handleClickAction = (item) => {

    if (!isLoggedIn && !item.free) {
      navigate("/login");
    } else if (!checkPermission(item)) {
      setConteudo("Você não possui permissão para acessar o módulo. Por favor, entre em contato com um administrador ou a pessoa responsável pela gestão da sua empresa para obter assistência.");
      setOpenModal(true);
      return;
    } else {
      navigate(item.link);
    }
  };

  return (
    <Grid container>
      {renderCardActionArea(product)}
      <ResponsiveDialog
        content={conteudo}
        openModal={openModal}
        title="Atenção"
        onClose={modalClose}
        isFullscreen={false}
      />
    </Grid>
  );
}
