import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ModalDialog({ openModal, title, content, onClose, isFullscreen, maxWidth, fullWidth, dialogActionsContent }) {

  return (
    <Dialog 
        open={openModal}
        onClose={onClose}
        fullScreen={isFullscreen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button className="BackButton" variant="contained" onClick={onClose}>Fechar</Button>
          {dialogActionsContent}
        </DialogActions>
      </Dialog>
  );
}