import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import CardContent from "@mui/material/CardContent";
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Auth } from "../../Services/API/api";
import { User } from "../../Models/User"

import logo from "../../assets/img/Logo-login.png";

import "../../App.css";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setTextError("");

    if (!username || !password) {
      setError(true);
      setTextError("Necessário Informar usuário e senha");
    } else {
      Auth(username, password)
        .then((response) => {
          if (response.erro === undefined) {
            let user = User.searchUser();
            user.saveInfo(response);
            navigate("/");
          } else {
            setError(true);
            setTextError(response.erro[0]);
          }
        })
    }
  };

  const ShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box className="login" height="100vh" display="flex" alignItems="center">
      <Helmet>
        <title>Peão Digital - Login</title>
      </Helmet>

      <Grid container justifyContent="center">
        <Grid item xs={11} sm={6} md={4} lg={3}>
          <Card className="card-login">
            <CardContent className="card-login-content">
              <Grid container display="flex" align-content="center" justifyContent="center" spacing={2}>
                <Grid marginTop={2}>
                  <img src={logo} alt="Logo" width="200"></img>
                </Grid>
              </Grid>

              <form onSubmit={handleSubmit}>
                <Grid marginBottom={2} marginTop={2} >
                  <FormControl fullWidth>
                    <TextField id="user" label="Usuário" value={username}
                      onChange={(event) => setUsername(event.target.value)}
                      style={{ marginTop: "20px" }}
                    />
                  </FormControl>
                </Grid>

                <Grid marginBottom={2} marginTop={2}>
                  <FormControl fullWidth>
                    <TextField id="password" label="Password" value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type={showPassword ? 'text' : 'password'}
                      style={{ marginTop: "20px" }}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton onClick={ShowPassword} edge="end">
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                  </FormControl>
                </Grid>

                {error && (
                  <Typography color="error" gutterBottom>{textError}</Typography>
                )}

                <Grid marginBottom={2} marginTop={2} container direction="row" justifyContent="flex-end" alignItems="stretch">
                  <Link className="link-password" to="https://api.whatsapp.com/send/?phone=5496589416&text=Ol%C3%A1,+preciso+de+ajuda+com+minha+senha+da+Pe%C3%A3o+Digital">Esqueceu sua senha?</Link>
                </Grid>

                <Grid display="flex" align-content="center" justifyContent="center">
                  <Button className="successBtn" type="submit" variant="contained" fullWidth><h3>Entrar</h3></Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <div id="mascara"></div>
      </Grid>
    </Box>
  );
}
