
import { DefaultModel } from "../DefaultModel";
import {
  searchAgronomicPrescription,
  addAgronomicPrescription,
  updateAgronomicPrescription,
  deleteAgronomicPrescription,
  deleteAgronomicPrescriptionItem,
  generateAgronomicPrescriptionPDF
} from "../../Services/API/api";

export class AgronomicPrescription extends DefaultModel {

  async SearchAgronomicPrescription(parameters) {
    const response = await searchAgronomicPrescription(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async AddAgronomicPrescription(parameters) {
    const response = await addAgronomicPrescription(this.token, parameters);
    return (response) || [];
  }

  async UpdateAgronomicPrescription(id, parameters) {
    const response = await updateAgronomicPrescription(this.token, id, JSON.stringify(parameters));
    return (response) || [];
  }

  async DeleteAgronomicPrescription(id) {
    const response = await deleteAgronomicPrescription(this.token, id);
    return (response) || [];
  }

  async DeleteAgronomicPrescriptionItem(id) {
    const response = await deleteAgronomicPrescriptionItem(this.token, id);
    return (response) || [];
  }

  async GenerateAgronomicPrescriptionPDF(id) {
    const response = await generateAgronomicPrescriptionPDF(this.token, id);
    return (response) || [];
  }

}