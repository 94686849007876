import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Grid,
  AppBar,
  Toolbar,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material/';

import Logo from '../../assets/img/Logo.png';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import { User } from '../../Models/User';

import './css/index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#009951'
    },
  },
});

export default function Header({ isLoggedIn }) {
  const user = User.searchUser();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    user.deleteInfo();
    navigate('/');
    window.location.reload();
  };

  const renderMenu = () => {
    return (
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ color: '#009951', fontWeight: '600' }}>
          Seja bem vindo, {user.nome}
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small"/>
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    );
  };

  const renderLoggedInContent = () => {
    return (
      <Grid>
        <Tooltip title="Minha Conta">
          <IconButton
            className='btn-account-header'
            onClick={handleClick}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'account-menu' : undefined}
          >
            <Avatar sx={{ width: 40, height: 40, color: '#009951', backgroundColor: 'white' }}></Avatar>
          </IconButton>
        </Tooltip>
        {renderMenu()}
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar className="AppBar" position="fixed" mb={4}>
        <Toolbar>
          <Grid sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img className="LogoImg" src={Logo} alt="Logo"></img>
            </Link>
          </Grid>
          {isLoggedIn ? renderLoggedInContent() : (
            <Link className="link-login" to="/login">
              <h3><LoginIcon /> Entrar</h3>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
