import React, { Component } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid } from '@mui/material';
import { randomString } from '../../../Services/globalFunction';

export default class MultiLevelTable extends Component{
  constructor({data, typeData}) {
    super()

    this.data = data
    this.typeData = typeData
  }

  render_data(row) {
    var content = []

    var createFinalLevel = rows => {
      rows.forEach(row => {
        content.push(
          <Grid container item lg={12} md={12} sm={12} xs={12} style={{padding: "4px"}}>
            <Grid item key={randomString()} lg={6} md={6} sm={10} xs={10} style={{textAlign: "center"}}>
              {this.typeData === 'fazenda'? row.piloto + ' | ': ''} {this.typeData === 'maquina'? row.piloto: row.maquina} 
            </Grid>
            <Grid item key={randomString()} lg={6} md={6} sm={2} xs={2} style={{textAlign: "end"}}>
              {row.qtd.toLocaleString('pt-br', {minimumFractionDigits: 2})}
            </Grid>
          </Grid>
        )
      })
    } 

    row[this.typeData === 'fazenda'? 'talhoes': 'datas'].forEach((row2, index2) => {
      content.push(
        <Grid container item lg={12} md={12} sm={12} xs={12} style={{backgroundColor: "#04AA6D", padding: "4px"}}>
          <Grid item key={randomString()} lg={6} md={6} sm={8} xs={10}>
            <b>{'data' in row2? row2.data:row2.talhao}</b>
          </Grid>
          <Grid item key={randomString()} lg={6} md={6} sm={4} xs={2} style={{textAlign: "end"}}>
            <b>{row2.total.toLocaleString('pt-br', {minimumFractionDigits: 2})}</b>
          </Grid>
        </Grid>
      )

      if (this.typeData !== 'fazenda') {
        row2['talhoes'].forEach((row3, index3) => {
          content.push(
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{padding: "4px"}}>
              <Grid item key={randomString()} lg={6} md={6} sm={8} xs={10}>
                {row3.talhao}
              </Grid>
              <Grid item key={randomString()} lg={6} md={6} sm={4} xs={2} style={{textAlign: "end"}}>
               {row3.total.toLocaleString('pt-br', {minimumFractionDigits: 2})}
              </Grid>
            </Grid>
          )

          createFinalLevel(row3.registros)
        })
      } else {
        createFinalLevel(row2.registros)
      }

    })

    return content
  }

  render() {
    
    return (
      <Grid container item lg={12} md={12} sm={12} xs={12}>

        {this.data.map(row => {
          return (
            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: "5px"}}>
        
              <Accordion>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} style={{backgroundColor: "#1c2723", color: "white"}}>

                  <Grid container item lg={12} md={12} sm={12} xs={12} key={randomString()} >
                    <Grid key={randomString()} item lg={9} md={6} sm={12} xs={12}>
                      <b>{'fazenda' in row? row.fazenda:row.data}</b>
                    </Grid>

                    <Grid key={randomString()} item lg={3} md={6} sm={12} xs={12} style={{textAlign: "end"}}>
                      <b>{row.total}</b>
                    </Grid>
                  </Grid>

                </AccordionSummary>
                <AccordionDetails>
                  {this.render_data(row)}
                </AccordionDetails>
              </Accordion>

            </Grid>
          )
        })}

      </Grid>
    )
  }
}
