export const dataLimitation = [
  { id: "EMPRESA", nome: "Empresa" },
  { id: "FAZENDA", nome: "Fazenda" },
  { id: "FILIAL", nome: "Filial" },
];

export const dataParentCategory = [
  { id: "S", nome: "Sim" },
  { id: "N", nome: "Não" },
];

export const dataTypeCategory = [
  { id: "ENTRADA", nome: "Entrada" },
  { id: "SAIDA", nome: "Saída" },
];