const unidadesAgrupadas = [

  { nome: "metro quadrado", indice_conversao: 1, tipo: "Área" },
  { nome: "hectare", indice_conversao: 10000, tipo: "Área" },
  { nome: "acre", indice_conversao: 4046.86, tipo: "Área" },
  { nome: "centímetro quadrado", indice_conversao: 0.0001, tipo: "Área" },
  { nome: "quilômetro quadrado", indice_conversao: 1000000, tipo: "Área" },
  { nome: "alqueire paulista", indice_conversao: 24200, tipo: "Área" },
  { nome: "alqueire mineiro", indice_conversao: 48400, tipo: "Área" },
  { nome: "milha quadrada", indice_conversao: 2589988.11, tipo: "Área" },

  { nome: "metro cúbico", indice_conversao: 1, tipo: "Volume" },
  { nome: "litro", indice_conversao: 0.001, tipo: "Volume" },
  { nome: "mililitro", indice_conversao: 0.000001, tipo: "Volume" },
  { nome: "galão americano", indice_conversao: 0.00378541, tipo: "Volume" },
  { nome: "galão imperial", indice_conversao: 0.00454609, tipo: "Volume" },
  { nome: "bushel", indice_conversao: 0.0352391, tipo: "Volume" },
  { nome: "pé cúbico", indice_conversao: 0.0283168, tipo: "Volume" },
  { nome: "barril de petróleo", indice_conversao: 0.158987, tipo: "Volume" },

  { nome: "quilograma", indice_conversao: 1, tipo: "Peso" },
  { nome: "grama", indice_conversao: 0.001, tipo: "Peso" },
  { nome: "tonelada", indice_conversao: 1000, tipo: "Peso" },
  { nome: "libra", indice_conversao: 0.453592, tipo: "Peso" },
  { nome: "onça", indice_conversao: 0.0283495, tipo: "Peso" },
  { nome: "arroba", indice_conversao: 15, tipo: "Peso" },
  { nome: "saca de 60 kg", indice_conversao: 60, tipo: "Peso" },
  { nome: "bushel (soja)", indice_conversao: 27.216, tipo: "Peso" },
  { nome: "bushel (milho)", indice_conversao: 25.4, tipo: "Peso" },

  { nome: "metro", indice_conversao: 1, tipo: "Comprimento" },
  { nome: "centímetro", indice_conversao: 0.01, tipo: "Comprimento" },
  { nome: "quilômetro", indice_conversao: 1000, tipo: "Comprimento" },
  { nome: "milímetro", indice_conversao: 0.001, tipo: "Comprimento" },
  { nome: "polegada", indice_conversao: 0.0254, tipo: "Comprimento" },
  { nome: "pé", indice_conversao: 0.3048, tipo: "Comprimento" },
  { nome: "jarda", indice_conversao: 0.9144, tipo: "Comprimento" },
  { nome: "milha", indice_conversao: 1609.34, tipo: "Comprimento" },

  { nome: "segundo", indice_conversao: 1, tipo: "Tempo" },
  { nome: "minuto", indice_conversao: 60, tipo: "Tempo" },
  { nome: "hora", indice_conversao: 3600, tipo: "Tempo" },
  { nome: "dia", indice_conversao: 86400, tipo: "Tempo" },
  { nome: "semana", indice_conversao: 604800, tipo: "Tempo" },
  { nome: "mês (30 dias)", indice_conversao: 2592000, tipo: "Tempo" },
  { nome: "ano", indice_conversao: 31536000, tipo: "Tempo" },

  { nome: "metro por segundo", indice_conversao: 1, tipo: "Velocidade" },
  { nome: "quilômetro por hora", indice_conversao: 0.277778, tipo: "Velocidade" },
  { nome: "milha por hora", indice_conversao: 0.44704, tipo: "Velocidade" },
  { nome: "nó", indice_conversao: 0.514444, tipo: "Velocidade" },

  { nome: "polegada d'água por hectare", indice_conversao: 10000, tipo: "Irrigação" },
  { nome: "litros por hectare", indice_conversao: 10000, tipo: "Irrigação" },
  { nome: "milímetros de chuva por hectare", indice_conversao: 10, tipo: "Irrigação" },
];

export default unidadesAgrupadas;