import { DefaultModel } from "../DefaultModel";

import {
  addInventoryMovement,
  updateInventoryMovement,
  searchInventoryMovements,
  searchInventoryMovement,
  approveMovement,
  delInventoryItem,
  delInventoryMovement
} from "../../Services/API/api";

export class Movement extends DefaultModel {

  async SearchInventoryMovements(parameters) {
    const response = await searchInventoryMovements(this.token, this.converter_obj_query_string(parameters));
    return response;
  }

  async SearchInventoryMovement(id) {
    const response = await searchInventoryMovement(this.token, id);
    return response;
  }

  async AddInventoryMovement(parameters) {
    const response = await addInventoryMovement(this.token, JSON.stringify(parameters));
    return response;
  }

  async UpdateInventoryMovement(id, parameters) {
    const response = await updateInventoryMovement(this.token, id, JSON.stringify(parameters));
    return response;
  }

  async ApproveMovement(id) {
    const response = await approveMovement(this.token, id);
    return response;
  }

  async DeleteInventoryItem(id) {
    const response = await delInventoryItem(this.token, id);
    return response;
  }

  async DeleteInventoryMovement(id) {
    const response = await delInventoryMovement(this.token, id);
    return response;
  }

}