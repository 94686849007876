import { Box, Button, ButtonGroup, Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";

import { User } from "../../Models/User";
import { City } from '../../Models/Essential/CEP';
import { WeatherForecast } from '../../Models/Forecast/Forecast';

import { WeatherIcon } from "../../Services/globalFunction";

import AppBar from "../../Components/AppBar";
import WeatherTable from "../../Components/WeatherTable";
import ResponsiveDialog from "../../Components/Dialog/Dialog";
import AutocompleteSelect from '../../Components/Selects/AutocompleteSelect';

import { faClock, faDroplet, faEye, faTemperature0, faWind } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../App.css";
import { useNavigate } from "react-router-dom";

const getPeriod = (horario) => {
  const hour = parseInt(horario.substring(0, 2), 10);
  if (hour >= 0 && hour < 6) return 'Madrugada';
  if (hour >= 6 && hour < 12) return 'Manhã';
  if (hour >= 12 && hour < 18) return 'Tarde';
  return 'Noite';
};

const extractForecastDetails = (hora) => {
  const details = hora.detalhes["1 Hora"] || hora.detalhes["6 horas"];
  return {
    periodo: hora.periodo,
    umidade: hora.umidade + ' %' || 'Dados Não Encontrados',
    descricao: details?.descricao || 'Dados Não Encontrados',
    icon: details?.descricao_original || 'Dados Não Encontrados',
    velocidade_vento: hora.velocidade_vento + ' m/s' || 'Dados Não Encontrados',
    temperatura: Math.round(hora.temperatura) + ' ºC' || 'Dados Não Encontrados',
    precipitacao: details?.precipitacao + ' mm' || 'Dados Não Encontrados',
  };
};

const getWeeklyForecast = (forecast) => {
  const dataWeeklyForecast = [];
  const hourlyForecastData = [];

  forecast.datas.forEach(({ data, horarios, temperatura_max, temperatura_min }) => {
    let icons = { Madrugada: '', Manhã: '', Tarde: '', Noite: '' }; 

    const [day, month] = data.split('/');
    const formattedDate = `${day}/${month}`;

    horarios.forEach((hora) => {
      const {
        umidade,
        icon,
        velocidade_vento,
        temperatura,
        precipitacao
      } = extractForecastDetails(hora);
      const period = getPeriod(hora.horario);
      icons[period] = icon;

      hourlyForecastData.push({
        data,
        horario: hora.horario,
        umidade,
        temperatura,
        precipitacao,
        velocidade_vento
      });
    });

    dataWeeklyForecast.push({
      id: data,
      data: formattedDate,
      icon_madrugada: <WeatherIcon icon={icons['Madrugada']} width="35" />,
      icon_manha: <WeatherIcon icon={icons['Manhã']} width="35" />,
      icon_tarde: <WeatherIcon icon={icons['Tarde']} width="35" />,
      icon_noite: <WeatherIcon icon={icons['Noite']} width="35" />,
      temperatura: `${Math.round(temperatura_min)}º / ${Math.round(temperatura_max)}º`
    });
  });

  return { weeklyForecast: dataWeeklyForecast, hourlyForecast: hourlyForecastData };
};

const headers = ['Data', 'Madrugada', 'Manhã', 'Tarde', 'Noite', 'Temp. (ºC)'];
const columnColors = ['black', 'black', 'black', 'black', 'black', 'black'];

export default function Weather() {
  const [title, setTitle] = useState('Previsão diária');

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [weeklyForecast, setWeeklyForecast] = useState([]);
  const [hourlyForecast, setHourlyForecast] = useState([]);

  const [dataCities, setDataCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(3925);

  const [fullscreen, setFullscreen] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const validateUser = () => {
    const user = User.searchUser();
    if (user.isLoggedIn()) {
      setIsLoggedIn(true);
    }
  };

  const LoadWeatherForecast = useCallback(async () => {
    if (selectedCity) {
      const weatherForecast = new WeatherForecast();
      const forecast = await weatherForecast.SearchWeatherForecast(selectedCity);
      if (forecast.datas && forecast.datas.length > 0) {
        const { weeklyForecast: weekForecast, hourlyForecast: hourForecast } = getWeeklyForecast(forecast);

        setWeeklyForecast(weekForecast);
        setHourlyForecast(hourForecast);
      }
    }
  }, [selectedCity]);

  const LoadCities = async () => {
    const city = new City();
    const cities = await city.SearchCities('RS');
    setDataCities(cities);
  };

  const actions = (data) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => handleView(data)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  const handleView = async (data) => {
    const filteredHourlyForecast = hourlyForecast.filter(dados => dados.data === data);
    const rowsWithoutDate = filteredHourlyForecast.map(({ horario, umidade, temperatura, precipitacao, velocidade_vento }) => ({
      horario,
      umidade,
      temperatura,
      precipitacao,
      velocidade_vento,
    }));

    setTitle(`Previsão diária para ${data}`)

    const headers = [
      <span key="hora"><FontAwesomeIcon icon={faClock} /> Hora</span>,
      <span key="umidade"><FontAwesomeIcon icon={faDroplet} /> Umidade</span>,
      <span key="temp"><FontAwesomeIcon icon={faTemperature0} /> Temp</span>,
      <span key="precipitacao"><FontAwesomeIcon icon={faEye} /> Precipitação</span>,
      <span key="velocidade_vento"><FontAwesomeIcon icon={faWind} /> Vel. Vento</span>
    ];

    const colors = ['black', 'black', 'red', 'blue', 'black'];

    const content = () => (
      <Grid container justifyContent="center">
        <WeatherTable headers={headers} rows={rowsWithoutDate} actions={null} length={25} maxHeight={768} columnColors={colors} />
      </Grid>
    );

    setContentModal(content);
    setFullscreen(true);
    setOpenModal(true);
  };

  const handleCityChange = async (event) => {
    if (event) {
      setWeeklyForecast([]);
      const user = User.searchUser();
      user.saveCity(event.id)
      setSelectedCity(event.id);
    }
  }

  const handleClosePage = () =>{
    navigate("/");
  }

  const modalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    LoadCities();
  }, [])

  useEffect(() => {
    validateUser();
    LoadWeatherForecast();
  }, [LoadWeatherForecast]);

  return (
    <Box id="homePage">
      <Helmet>
        <title>Peão Digital - Início</title>
      </Helmet>

      <AppBar isLoggedIn={isLoggedIn} />

      <Grid container justifyContent="center" mt={8}>

        <Grid item lg={12} md={12} sm={12} xs={12} mt={2} textAlign="center">
          <Typography className="titles-pages" variant="h5">PREVISÃO DO TEMPO</Typography>
        </Grid>

        <Grid item lg={10} md={12} sm={12} xs={12}>

          <Grid item lg={2} md={12} sm={12} xs={12} m={2}>
            <Button className="BackButton" fullWidth onClick={handleClosePage}>Voltar</Button>
          </Grid>

          <Card>
            <CardContent>
              <Grid item lg={4} md={4} sm={12} xs={12} justifyContent="flex-end">
                <AutocompleteSelect
                  id="city"
                  label="Cidade"
                  disabled={false}
                  data={dataCities}
                  isMultiple={false}
                  value={selectedCity}
                  onChange={handleCityChange}
                />
              </Grid>

              <WeatherTable headers={headers} rows={weeklyForecast} actions={actions} length={7} columnColors={columnColors} />
            </CardContent>
          </Card>
        </Grid>

        <ResponsiveDialog
          title={title}
          content={contentModal}
          onClose={modalClose}
          openModal={openModal}
          isFullscreen={fullscreen}
        />
      </Grid>
    </Box>
  );
}