import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { User } from '../../Models/User';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LogoText from "../../assets/img/Logo.png";

import "./css/index.css";

const drawerWidth = 240;
const drawerWidthClosed = 45;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#009951",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerWidthClosed,
  backgroundColor: "#009951",
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthClosed,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        overflowX: 'auto',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        overflowX: 'hidden',
      },
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: theme.spacing(2, 1),
}));

const iconMap = {
  faPlus: solidIcons.faPlus,
  faList: solidIcons.faList,
  faHouse: solidIcons.faHouse,
  faFilePdf: solidIcons.faFilePdf,
  faPowerOff: solidIcons.faPowerOff,
  faCircleXmark: solidIcons.faCircleXmark,
  faCircleChevronRight: solidIcons.faCircleChevronRight,
  faCircleChevronLeft: solidIcons.faCircleChevronLeft,
  faArrowRightArrowLeft: solidIcons.faArrowRightArrowLeft
};

export default function Sidebar({ isLoggedIn, routes, onButtonClick, children }) {
  const [activeRoute, setActiveRoute] = useState('');
  const [open, setOpen] = useState(true);

  const user = User.searchUser();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    user.deleteInfo();
    navigate('/');
    window.location.reload();
  }

  const handleToggleSidebar = () => {
    setOpen(!open);
  };

  const getFontAwesomeIcon = (iconName) => {
    return iconMap[iconName] || null; // Retorna o ícone correspondente ou null se não for encontrado
  };

  useEffect(() => {
    const pathname = location.pathname;
    const lastPathSegment = pathname.substring(pathname.lastIndexOf('/'));
    setActiveRoute(lastPathSegment);
  }, [location.pathname, routes]);

  return (
    <Box className="formulario">
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>

          {open && <Link to="/"><img className="LogoImg" src={LogoText} alt="Logo"></img></Link>}

          <IconButton className="expandBtn" onClick={handleToggleSidebar}>
            {open ? <FontAwesomeIcon className="font-icons" icon={getFontAwesomeIcon('faCircleXmark')} /> : <FontAwesomeIcon className="font-icons" icon={getFontAwesomeIcon('faCircleChevronRight')} />}
          </IconButton>
        </DrawerHeader>

        <List>
          <Tooltip title='Início' placement="right-start">
            <ListItem disablePadding>
              <ListItemButton className='sidebarBtn' sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, padding: open ? '10px' : '20px', mr: 0}} to="/">
                <ListItemIcon className='sidebarIcon' sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                  <FontAwesomeIcon className="font-icons" icon={getFontAwesomeIcon('faHouse')} />
                </ListItemIcon>
                {open && <ListItemText className='listText' primary="Início" />}
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {routes.map((val) => (
            <Tooltip key={val.id} title={val.nome} placement="right-start">
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, mr: 0,padding: open ? '10px' : '20px'}}
                  onClick={() => onButtonClick(val.id, val.rota)}
                  className={activeRoute === val.rota ? 'activeBtn sidebarBtn' : 'sidebarBtn'}
                  disabled={activeRoute === val.rota}
                >
                  <ListItemIcon className={activeRoute === val.rota ? 'activeIcon sidebarIcon' : 'sidebarIcon'} sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={getFontAwesomeIcon(val.icone)} />
                  </ListItemIcon>
                  {open && <ListItemText className={activeRoute === val.rota ? 'activeText listText' : 'listText'} primary={val.nome} />}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>

        {isLoggedIn ? (
          <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <ListItem disablePadding>
              <ListItemButton className='sidebarBtn' onClick={logout}>
                <ListItemIcon className='sidebarIcon' sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                  <FontAwesomeIcon className="font-icons" icon={getFontAwesomeIcon('faPowerOff')} />
                </ListItemIcon>
                {open && <ListItemText className='listText' primary="Sair" />}
              </ListItemButton>
            </ListItem>
          </List>
        ) : (
          <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <ListItem disablePadding>
              <ListItemButton className='sidebarBtn' to="/login">
                <ListItemIcon className='sidebarIcon' sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                  <FontAwesomeIcon className="font-icons" icon={getFontAwesomeIcon('faPowerOff')} />
                </ListItemIcon>
                {open && <ListItemText className='listText' primary="Entrar" />}
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, mr: 1, ml: 7 }} open={open}>
        <DrawerHeader />
        {children}
        <div id="mascara"></div>
      </Box>
    </Box >
  );
}