export const clearsky_day = require("../assets/symbol/clearsky_day.svg");
export const clearsky_night = require("../assets/symbol/clearsky_night.svg");
export const clearsky_polartwilight = require("../assets/symbol/clearsky_polartwilight.svg");
export const fair_day = require("../assets/symbol/fair_day.svg");
export const fair_night = require("../assets/symbol/fair_night.svg");
export const fair_polartwilight = require("../assets/symbol/fair_polartwilight.svg");
export const partlycloudy_day = require("../assets/symbol/partlycloudy_day.svg");
export const partlycloudy_night = require("../assets/symbol/partlycloudy_night.svg");
export const partlycloudy_polartwilight = require("../assets/symbol/partlycloudy_polartwilight.svg");
export const cloudy = require("../assets/symbol/cloudy.svg");
export const rainshowers_day = require("../assets/symbol/rainshowers_day.svg");
export const rainshowers_night = require("../assets/symbol/rainshowers_night.svg");
export const rainshowers_polartwilight = require("../assets/symbol/rainshowers_polartwilight.svg");
export const rainshowersandthunder_day = require("../assets/symbol/rainshowersandthunder_day.svg");
export const rainshowersandthunder_night = require("../assets/symbol/rainshowersandthunder_night.svg");
export const rainshowersandthunder_polartwilight = require("../assets/symbol/rainshowersandthunder_polartwilight.svg");
export const sleetshowers_day = require("../assets/symbol/sleetshowers_day.svg");
export const sleetshowers_night = require("../assets/symbol/sleetshowers_night.svg");
export const sleetshowers_polartwilight = require("../assets/symbol/sleetshowers_polartwilight.svg");
export const snowshowers_day = require("../assets/symbol/snowshowers_day.svg");
export const snowshowers_night = require("../assets/symbol/snowshowers_night.svg");
export const snowshowers_polartwilight = require("../assets/symbol/snowshowers_polartwilight.svg");
export const rain = require("../assets/symbol/rain.svg");
export const heavyrain = require("../assets/symbol/heavyrain.svg");
export const heavyrainandthunder = require("../assets/symbol/heavyrainandthunder.svg");
export const sleet = require("../assets/symbol/sleet.svg");
export const snow = require("../assets/symbol/snow.svg");
export const snowandthunder = require("../assets/symbol/snowandthunder.svg");
export const fog = require("../assets/symbol/fog.svg");
export const sleetshowersandthunder_day = require("../assets/symbol/sleetshowersandthunder_day.svg");
export const sleetshowersandthunder_night = require("../assets/symbol/sleetshowersandthunder_night.svg");
export const sleetshowersandthunder_polartwilight = require("../assets/symbol/sleetshowersandthunder_polartwilight.svg");
export const snowshowersandthunder_day = require("../assets/symbol/snowshowersandthunder_day.svg");
export const snowshowersandthunder_night = require("../assets/symbol/snowshowersandthunder_night.svg");
export const snowshowersandthunder_polartwilight = require("../assets/symbol/snowshowersandthunder_polartwilight.svg");
export const rainandthunder = require("../assets/symbol/rainandthunder.svg");
export const sleetandthunder = require("../assets/symbol/sleetandthunder.svg");
export const lightrainshowersandthunder_day = require("../assets/symbol/lightrainshowersandthunder_day.svg");
export const lightrainshowersandthunder_night = require("../assets/symbol/lightrainshowersandthunder_night.svg");
export const lightrainshowersandthunder_polartwilight = require("../assets/symbol/lightrainshowersandthunder_polartwilight.svg");
export const heavyrainshowersandthunder_day = require("../assets/symbol/heavyrainshowersandthunder_day.svg");
export const heavyrainshowersandthunder_night = require("../assets/symbol/heavyrainshowersandthunder_night.svg");
export const heavyrainshowersandthunder_polartwilight = require("../assets/symbol/heavyrainshowersandthunder_polartwilight.svg");
export const lightssleetshowersandthunder_day = require("../assets/symbol/lightssleetshowersandthunder_day.svg");
export const lightssleetshowersandthunder_night = require("../assets/symbol/lightssleetshowersandthunder_night.svg");
export const lightssleetshowersandthunder_polartwilight = require("../assets/symbol/lightssleetshowersandthunder_polartwilight.svg");
export const heavysleetshowersandthunder_day = require("../assets/symbol/heavysleetshowersandthunder_day.svg");
export const heavysleetshowersandthunder_night = require("../assets/symbol/heavysleetshowersandthunder_night.svg");
export const heavysleetshowersandthunder_polartwilight = require("../assets/symbol/heavysleetshowersandthunder_polartwilight.svg");
export const lightssnowshowersandthunder_day = require("../assets/symbol/lightssnowshowersandthunder_day.svg");
export const lightssnowshowersandthunder_night = require("../assets/symbol/lightssnowshowersandthunder_night.svg");
export const lightssnowshowersandthunder_polartwilight = require("../assets/symbol/lightssnowshowersandthunder_polartwilight.svg");
export const heavysnowshowersandthunder_day = require("../assets/symbol/heavysnowshowersandthunder_day.svg");
export const heavysnowshowersandthunder_night = require("../assets/symbol/heavysnowshowersandthunder_night.svg");
export const heavysnowshowersandthunder_polartwilight = require("../assets/symbol/heavysnowshowersandthunder_polartwilight.svg");
export const lightrainandthunder = require("../assets/symbol/lightrainandthunder.svg");
export const lightsleetandthunder = require("../assets/symbol/lightsleetandthunder.svg");
export const heavysleetandthunder = require("../assets/symbol/heavysleetandthunder.svg");
export const lightsnowandthunder = require("../assets/symbol/lightsnowandthunder.svg");
export const heavysnowandthunder = require("../assets/symbol/heavysnowandthunder.svg");
export const lightrainshowers_day = require("../assets/symbol/lightrainshowers_day.svg");
export const lightrainshowers_night = require("../assets/symbol/lightrainshowers_night.svg");
export const lightrainshowers_polartwilight = require("../assets/symbol/lightrainshowers_polartwilight.svg");
export const heavyrainshowers_day = require("../assets/symbol/heavyrainshowers_day.svg");
export const heavyrainshowers_night = require("../assets/symbol/heavyrainshowers_night.svg");
export const heavyrainshowers_polartwilight = require("../assets/symbol/heavyrainshowers_polartwilight.svg");
export const lightsleetshowers_day = require("../assets/symbol/lightsleetshowers_day.svg");
export const lightsleetshowers_night = require("../assets/symbol/lightsleetshowers_night.svg");
export const lightsleetshowers_polartwilight = require("../assets/symbol/lightsleetshowers_polartwilight.svg");
export const heavysleetshowers_day = require("../assets/symbol/heavysleetshowers_day.svg");
export const heavysleetshowers_night = require("../assets/symbol/heavysleetshowers_night.svg");
export const heavysleetshowers_polartwilight = require("../assets/symbol/heavysleetshowers_polartwilight.svg");
export const lightsnowshowers_day = require("../assets/symbol/lightsnowshowers_day.svg");
export const lightsnowshowers_night = require("../assets/symbol/lightsnowshowers_night.svg");
export const lightsnowshowers_polartwilight = require("../assets/symbol/lightsnowshowers_polartwilight.svg");
export const heavysnowshowers_day = require("../assets/symbol/heavysnowshowers_day.svg");
export const heavysnowshowers_night = require("../assets/symbol/heavysnowshowers_night.svg");
export const heavysnowshowers_polartwilight = require("../assets/symbol/heavysnowshowers_polartwilight.svg");
export const lightrain = require("../assets/symbol/lightrain.svg");
export const lightsleet = require("../assets/symbol/lightsleet.svg");
export const heavysleet = require("../assets/symbol/heavysleet.svg");
export const lightsnow = require("../assets/symbol/lightsnow.svg");
export const heavysnow = require("../assets/symbol/heavysnow.svg");