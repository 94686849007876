export class User {
  token = null;
  user_id = null;
  nome = null;
  grupos = null;
  modulos = null;
  empresa_id = null;
  empresa_nome = null;
  cidade_previsao = null;

  static searchUser() {
    let user = new User();
    return user;
  }

  constructor() {
    this.searchStorageInfo();
  }

  searchStorageInfo() {
    this.token = localStorage.getItem('token');
    this.user_id = localStorage.getItem('user_id');
    this.nome = localStorage.getItem('nome');
    this.grupos = JSON.parse(localStorage.getItem('grupos'));
    this.modulos = JSON.parse(localStorage.getItem('modulos'));
    this.empresa_id = localStorage.getItem('empresa_id');
    this.empresa_nome = localStorage.getItem('empresa_nome');
    this.cidade_previsao = localStorage.getItem('cidade_previsao');
  }

  saveInfo(response) {

    let grupos = JSON.stringify(response.grupos);
    let modulos = JSON.stringify(response.modulos);

    localStorage.setItem('token', response.token);
    localStorage.setItem('user_id', response.user_id);
    localStorage.setItem('nome', response.nome);
    localStorage.setItem('grupos', grupos);
    localStorage.setItem('modulos', modulos);
    localStorage.setItem('empresa_id', response.empresa.empresa_id ? response.empresa.empresa_id : null);
    localStorage.setItem('empresa_nome', response.empresa.empresa ? response.empresa.empresa : null);

    this.searchStorageInfo();
  }

  deleteInfo() {
    this.token = null;
    this.user_id = null;
    this.nome = null;
    this.modulos = null;
    this.grupos = null;
    this.empresa_id = null;
    this.empresa_nome = null;
    this.cidade_previsao = null;

    localStorage.removeItem('token');
    localStorage.removeItem('nome');
    localStorage.removeItem('grupos');
    localStorage.removeItem('user_id');
    localStorage.removeItem('modulos');
    localStorage.removeItem('empresa_id');
    localStorage.removeItem('empresa_nome');
    localStorage.removeItem('cidade_previsao');
  }

  saveCity(cidade) {
    localStorage.setItem('cidade_previsao', cidade);
  }

  isLoggedIn() {
    return this.token != null;
  }

}