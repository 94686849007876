import { DefaultModel } from "../DefaultModel";
import { searchMachinery, searchPropertyMachinery } from "../../Services/API/api";

export class Machinery extends DefaultModel {

  async SearchMachinery() {
    const response = await searchMachinery(this.token);
    return (response) || [];
  }

  async SearchPropertyMachinery(propertyId) {
    const response = await searchPropertyMachinery(this.token, propertyId);
    return (response) || [];
  }
}