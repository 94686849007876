import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export class SimpleColumnChart extends Component {
  constructor({id, title, categories, series}) {
    super();

    this.state = {
      options: {
        chart: {
          id: id
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false,          
        },
        xaxis: {
          categories: categories,
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString('pt-br', {minimumFractionDigits: 2})
            }
          }
        },
        title: {
          text: title,
          align: 'center',
          floating: true
        }
      },
      series: series
    };
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="bar"
      />
    );
  }
}

export class PieChart extends Component {
  constructor({id, title, labels, series}) {
    super();

    this.state = {
      series: series,
      options: {
        chart: {
          id: id,
        },
        legend: {
          formatter: function(seriesName, opts) {
            let percent = opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1)
            return `${opts.w.globals.labels[opts.seriesIndex]} (${percent}%)`
          }
        },
        title: {
          text: title,
          align: 'center'
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString('pt-br', {minimumFractionDigits: 2})
            }
          }
        },
        labels: labels
      },
    
    };
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="pie"
      />
    );
  }
}

export class TreeMapChart extends Component {
  constructor({id, title, data}) {
    super();

    this.state = {
      series: [{data: data}],
      options: {
        legend: {
          show: false
        },
        chart: {
          height: 450,
          id: id
        },
        title: {
          text: title
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString('pt-br', {minimumFractionDigits: 2})
            }
          }
        },
      },
    };
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="treemap"
      />
    );
  }
}

export class SimpleLineDateChart extends Component {

  constructor({id, title, categories, series}){
    super();
    this.state = {
          
      series: series,
      options: {
        chart: {
          id: id,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val, opt) {
            return val.toLocaleString('pt-br', {minimumFractionDigits: 2})
          }
        },
        markers: {
          size: 0,
        },
        title: {
          text: title,
          align: 'left'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0);
            },
          },
          title: {
            text: 'Price'
          },
        },
        xaxis: {
          categories: categories
        }
      },
    };

  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="area"
      />
    );
  }
}