import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';


import "./css/index.css";

export default function ResponsiveDialog({ openModal, title, content, onClose, isFullscreen }) {

  return (
    <Dialog fullScreen={isFullscreen} open={openModal}>
      <DialogTitle className='dialog-title'>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className='dialog-content'>
        {content}
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button className="BackButton" variant="contained" autoFocus onClick={onClose}>Voltar</Button>
      </DialogActions>
    </Dialog>
  );
}