import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CalculateIcon from '@mui/icons-material/Calculate';

export const list_products = [
  { id: 1, permission: "calculadoras", title: 'Calculadoras', link: '/calculadoras/conversor', icon: <CalculateIcon className='iconProduct' />, free: true },
  //{ id: 2, permission: 'estoque', title: 'Controle de Estoque', link: '/estoque/cadastro-movimentacoes', icon: <InventoryIcon className='iconProduct' />, free: false },
  { id: 2, permission: 'propriedades', title: 'PROPRIEDADES', link: '/propriedades/listagem-propriedades', icon: <HomeIcon className='iconProduct' />, free: false },
  { id: 3, permission: 'registro_atividades_pulverizacao', title: 'Gestor de Atividades', link: '/gestor-atividades/relatorio-atividades', icon: <PendingActionsIcon className='iconProduct' />, free: false },
  { id: 4, permission: 'receitas', title: 'Receituário agronômico', link: '/receituario-agronomico/cadastro-receitas', icon: <LocalFloristIcon className='iconProduct' />, free: false },
  { id: 5, permission: 'financeiro', title: 'Registro Finanças', link: '/financeiro/cadastro-financas', icon: <InventoryIcon className='iconProduct' />, free: false },
];