import { DefaultModel } from "../DefaultModel";
import { searchContractor} from "../../Services/API/api";

export class Contractor extends DefaultModel {

  async SearchContractor() {
    const response = await searchContractor(this.token);
    return (response) || []; 
  }

}

