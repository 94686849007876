import { DefaultModel } from "../DefaultModel";
import { searchGroup, searchItemGroup } from "../../Services/API/api";

export class ItemGroup extends DefaultModel {
  async SearchGroup() {
    const response = await searchGroup(this.token);
    return (response) || [];
  }

  async SearchItemGroup(grupo_id) {
    const response = await searchItemGroup(this.token, grupo_id);
    return (response) || [];
  }

}