import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';

import Alertavel from '../../../Components/Alertavel';
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";
import ModalDialog from '../../../Components/ModalDialog/ModalDialog';
import MultiLevelTable from '../Components'

import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Tooltip, TextField, Typography } from '@mui/material';

import { User } from '../../../Models/User';
import { Company } from '../../../Models/Organization/Company';
import { Machinery } from '../../../Models/Machinery/Machinery';
import { Activity } from '../../../Models/Activities/Activities';
import { Property } from '../../../Models/Organization/Property';

import { dataType, dataTypeActivities, dataPilotStatus } from '../constants';
import { getCurrentDate, randomString, showLoading } from '../../../Services/globalFunction';

import '../Css/ReportActivities.css';

const ReportActivities = ({ module }) => {
  const title = 'Relatório de Atividades'
  const [reportContent, setReportContent] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  const [modalData, setModalData] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  var dataReport = [];

  /** Controles do modal */
  const [modalStatus, setModalStatus] = useState(false);

  /** Estados para exibir alertas*/
  const [openAlert, setOpenAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState('');

  /** Estados para armazenar dados de talhões, máquinas e propriedades*/
  const [dataField, setdataField] = useState([]);
  const [dataPilot, setDataPilot] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataMachinery, setdataMachinery] = useState([]);

  /** Estado para armazenar os dados do formulário. */
  const [relForm, setRelForm] = useState({
    data_inicial: getCurrentDate(0, 30, 'subtrai'),
    data_final: getCurrentDate(0, 0, ''),
    tipo_pulverizacao: null,
    situacao_piloto: 'ATIVOS',
    propriedade: [],
    tipo: null,
    maquina: [],
    talhao: [],
    piloto: [],
  });

  /** Obter o usuário e o token */
  const user = User.searchUser();
  const token = user.token;

  /** Função para carregar a lista de propriedades.*/
  const LoadProperty = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0
    }

    let properties = new Property(token);
    await properties.SearchProperty(parameters).then(response => {
      if (response.length > 0) {
        const newdataProperty = [{ id: 0, nome: 'Todos' }, ...response];
        return setDataProperty(newdataProperty);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, [module.modulo, token]);

  /** Função para carregar as máquinas com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadMachinery = async (propertyId) => {
    let machinery = new Machinery(token);

    await machinery.SearchPropertyMachinery(propertyId).then(response => {
      if (response.length > 0) {
        const newDataMachinery = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.numero + " - " + item.nome }))];
        return setdataMachinery(newDataMachinery);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar o maquinário. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  };

  /** Função para carregar os pilotos. */
  const LoadPilots = useCallback(async (pilotStatus) => {
    let pilots = new Company(token);

    await pilots.SearchPilot(user.empresa_id ?? 0, pilotStatus?pilotStatus:relForm.situacao_piloto).then(response => {
      if (response.length > 0) {
        let formatted = response.map((pilot) => {
          return { 'id': pilot.id, 'nome': (pilot.first_name ?? pilot.username) + ' ' + pilot.last_name ?? '' }
        })

        let newData = [{ id: 0, nome: 'Todos' }, ...formatted];
        if (response.length === 1) {
          newData = formatted;
        }

        
        return setDataPilot(newData);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os pilotos. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }, [token, user.empresa_id, relForm.situacao_piloto]);

  /** Função para carregar os talhões com base na fazenda selecionada. @param {number} propertyId - Ids das fazendas selecionadas.*/
  const LoadField = async (propertyId) => {
    let fields = new Property(token);
    await fields.SearchPropertyField(propertyId).then(response => {
      if (response.length > 0) {
        const newdataField = [{ id: 0, nome: 'Todos' }, ...response.map(item => ({ id: item.id, nome: item.nome + " - " + item.fazenda_nome }))];
        return setdataField(newdataField);
      } else if (response.erro) {
        setContentAlert("Você não possui permissão para listar os Talhões. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
      } else {
        return [];
      }
    });
  }

  /** Manipulador de eventos para seleção de propriedades no filtro. @param {Array} event - Propriedades selecionadas.*/
  const PropertyChange = (event) => {
    if (event) {

      setRelForm({ ...relForm, maquina: [], talhao: [] });
      setdataMachinery([]);
      setdataField([]);

      const isAllSelected = event.some((property) => property.id === 0);

      if (isAllSelected) {
        const value = [0];

        const propertiesWithoutAll = event.filter((property) => property.id === 0);
        setRelForm({ ...relForm, talhao: [], propriedade: propertiesWithoutAll });
        LoadMachinery(value);
        LoadField(value);
      } else {
        const updateProperty = event.map((val) => ({ id: val.id, nome: val.nome }));
        const value = event.map((val) => (val.id));

        setRelForm((prevData) => ({ ...prevData, propriedade: updateProperty }));

        if (value.length > 0) {
          LoadMachinery(value);
          LoadField(value);
        }
      }
    }
  }

  /** Manipulador de eventos para seleção de máquinas no filtro. @param {Array} event - Máquinas selecionadas.*/
  const MachineryChange = (event) => {
    if (event) {

      const isAllSelected = event.some((machinery) => machinery.id === 0);

      if (isAllSelected) {
        const machineryWithoutTodos = event.filter((machinery) => machinery.id === 0);
        setRelForm({ ...relForm, maquina: machineryWithoutTodos });
      } else {
        const updateMachinery = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, maquina: updateMachinery }));
      }
    }
  }

  /** Manipulador de eventos para seleção de status de piloto no filtro. @param {Array} event Status de piloto selecionado.*/
  const PilotStatusChange = (event) => {
    setRelForm({ ...relForm, piloto: []});
    setDataPilot([]);
    LoadPilots(event?event.id:null);
  }

  /** Manipulador de eventos para seleção de máquinas no filtro. @param {Array} event - Pilotos selecionadas.*/
  const PilotChange = (event) => {
    if (event) {

      const isAllSelected = event.some((pilot) => pilot.id === 0);

      if (isAllSelected) {
        const pilotWithoutAll = event.filter((pilot) => pilot.id === 0);
        setRelForm({ ...relForm, piloto: pilotWithoutAll });
      } else {
        const updatePilot = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, piloto: updatePilot }));
      }
    }
  }

  /** Manipulador de eventos para seleção de talhões no filtro. @param {Array} event - Talhões selecionados.*/
  const FieldChange = (event) => {
    if (event) {
      const isAllSelected = event.some((field) => field.id === 0);

      if (isAllSelected) {
        const fieldWithoutAll = event.filter((field) => field.id === 0);
        setRelForm({ ...relForm, talhao: fieldWithoutAll });
      } else {
        const updateField = event.map((val) => ({ id: val.id, nome: val.nome }));
        setRelForm((prevData) => ({ ...prevData, talhao: updateField }));
      }
    }
  }

  const TypeActivityChange = (event) => {
    if (event) {
      setRelForm({ ...relForm, tipo_pulverizacao: event.id });
    }
  }

  /** Função para gerar os dados em tela */
  const ExecuteReport = async (isPdf) => {
    if (
      relForm.data_inicial &&
      relForm.data_final &&
      relForm.propriedade &&
      relForm.propriedade.length > 0 &&
      relForm.maquina &&
      relForm.maquina.length > 0 &&
      relForm.talhao &&
      relForm.talhao.length > 0 &&
      relForm.piloto &&
      relForm.piloto.length > 0 &&
      relForm.tipo &&
      (relForm.tipo_pulverizacao || relForm.tipo_pulverizacao === 0)
    ) {
      setOpenAlert(false);
      if (isPdf) {
        await LoadPDF();
      } else {
        await LoadReport();
      }
    } else {
      setContentAlert("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlert(true);
    }
  }

  /** Função para gerar o PDF */
  const LoadPDF = async () => {
    let { data_inicial, data_final, propriedade, maquina, talhao, piloto, tipo, tipo_pulverizacao } = relForm;

    let listProperties = propriedade.map((val) => val.id);
    let listMachinery = maquina.map((val) => val.id);
    let listFields = talhao.map((val) => val.id);
    let listPilots = piloto.map((val) => val.id);

    let parameters = {
      tipo,
      data_inicial,
      data_final,
      fazendas: listProperties.join(','),
      maquinas: listMachinery.join(','),
      talhoes: listFields.join(','),
      pilotos: listPilots.join(','),
      tipo_pulverizacao,
      formato_pdf: 'S'
    };

    showLoading(true);
    const activity = new Activity(token);
    const response = await activity.GenerateActivitiesReport(parameters);
    showLoading(false);

    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  /** Função para dados em tela */
  const LoadReport = async () => {
    setReportContent('')

    let { data_inicial, data_final, propriedade, maquina, talhao, piloto, tipo, tipo_pulverizacao } = relForm;

    let listProperties = propriedade.map((val) => val.id);
    let listMachinery = maquina.map((val) => val.id);
    let listFields = talhao.map((val) => val.id);
    let listPilots = piloto.map((val) => val.id);

    let parameters = {
      tipo,
      data_inicial,
      data_final,
      fazendas: listProperties.join(','),
      maquinas: listMachinery.join(','),
      talhoes: listFields.join(','),
      pilotos: listPilots.join(','),
      tipo_pulverizacao,
      formato_pdf: 'N'
    };

    showLoading(true);
    const activity = new Activity(token);
    const dados = await activity.GenerateActivitiesReport(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Um erro aconteceu. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return {};
      }
    })

    showLoading(false);

    setOpenAccordion(false)
    if(dados.registros.length === 0) {
      setReportContent(
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center" style={{marginTop: "1px"}}>

          <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
            Não existe dados para os filtros selecionados!
          </Grid>
          
        </Grid>)
    } else {
      dataReport = dados.registros

      let titulo = '', resumo = []
      if (tipo === 'PILOTO') {
        titulo = 'Resumo dos pilotos'
        resumo = buildReportContent('piloto', dados.resumo)
      } else if(tipo === 'MAQUINA') {
        titulo = 'Resumo das máquinas'
        resumo = buildReportContent('maquina', dados.resumo)
      } else if (tipo === 'FOCO_FAZENDA') {
        titulo = 'Resumo das fazendas'
        resumo = buildReportContent('fazenda', dados.resumo)
      }

      setReportContent(
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center" style={{marginTop: "1px"}}>
  
          <Grid container item lg={12} md={12} sm={12} xs={12}>
  
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography variant="h6" className="title-accordion">
                {titulo}
              </Typography>
            </Grid>
  
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Button size="small" variant="outlined" endIcon={<DownloadIcon />} style={{float: "inline-end"}} onClick={LoadPDF}>PDF</Button>
            </Grid>
  
          </Grid>
  
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <br />
  
            {resumo}
          </Grid>
  
        </Grid>
      )
    }
  }

  const openModalDetails = (typeData, dataTarget) => {
    setModalData('')
    setModalTitle(dataTarget)
    
    let dados = dataReport.filter(obj => obj[typeData] === dataTarget.toUpperCase())[0]
    dados = typeData === 'fazenda'? dados['datas']: dados['fazendas']
    var conteudo = <MultiLevelTable data={dados} typeData={typeData} />

    setModalData(conteudo)
    setModalStatus(true)
  }

  const buildReportContent = (tipo, dados) => {
    var resumo = []

    dados.forEach(info => {
      var contratantes = []
      info.contratantes.forEach(contratante => {
        contratantes.push(
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={9} md={6} sm={12} xs={12}>
              {contratante.contratante}
            </Grid>

            <Grid item lg={3} md={6} sm={12} xs={12}>
              {contratante.total.toLocaleString('pt-br', {minimumFractionDigits: 2})} hectares
            </Grid>
          </Grid>
        )
      })

      resumo.push(
        <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: "5px"}}>
      
          <Accordion  >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>

              <Grid container item lg={12} md={12} sm={12} xs={12} key={randomString()}>
                <Grid key={randomString()} item lg={9} md={6} sm={12} xs={12}>
                  <b>{info[tipo]}</b>
                </Grid>

                <Grid key={randomString()} item lg={3} md={6} sm={12} xs={12}>
                  {info.total} hectares
                </Grid>
              </Grid>

            </AccordionSummary>
            <AccordionDetails>

              {contratantes}

              <br />

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button size="small" variant="outlined" onClick={() => openModalDetails(tipo, info[[tipo]])}>Abrir registros detalhados</Button>
              </Grid>

            </AccordionDetails>
          </Accordion>

        </Grid>
      )
    })

    return resumo
  }

  useEffect(() => {
    LoadProperty();
    LoadPilots();
  }, [LoadPilots, LoadProperty]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title} - Gestor de Atividades</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h5">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>

        <Accordion expanded={openAccordion} onChange={(e) => { setOpenAccordion(!openAccordion)}}>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="painel_fitros">
            <Typography variant="h6" className="titles">Filtre os dados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  label="Data Inicial"
                  value={relForm.data_inicial}
                  onChange={(event) => setRelForm({ ...relForm, data_inicial: event.target.value })}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  label="Data Final"
                  value={relForm.data_final}
                  onChange={(event) => setRelForm({ ...relForm, data_final: event.target.value })}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="propriedade"
                  label="Propriedade"
                  isMultiple={true}
                  required={true}
                  disabled={false}
                  data={dataProperty}
                  value={relForm.propriedade}
                  onChange={PropertyChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="talhao"
                  label="Talhão"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataField}
                  value={relForm.talhao}
                  onChange={FieldChange}
                />
              </Grid>
              
              <Grid item lg={6} md={5} sm={6} xs={12}>
                <AutocompleteSelect
                  id="situacao_piloto"
                  label="Situação do piloto"
                  required={true}
                  isMultiple={false}
                  disabled={false}
                  data={ dataPilotStatus }
                  value={ relForm.situacao_piloto }
                  onChange={PilotStatusChange}
                />
              </Grid>

              <Grid item lg={6} md={7} sm={6} xs={12}>
                <AutocompleteSelect
                  id="piloto"
                  label="Pilotos"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataPilot}
                  value={relForm.piloto}
                  onChange={PilotChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AutocompleteSelect
                  id="maquinario"
                  label="Maquinário"
                  required={true}
                  isMultiple={true}
                  disabled={false}
                  data={dataMachinery}
                  value={relForm.maquina}
                  onChange={MachineryChange}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AutocompleteSelect
                  id="tipo_pulverizacao"
                  label="Tipo de pulverização"
                  required={true}
                  isMultiple={false}
                  disabled={false}
                  data={dataTypeActivities}
                  value={relForm.tipo_pulverizacao}
                  onChange={TypeActivityChange}
                />
              </Grid>

              <Grid item lg={10} md={10} sm={12} xs={12}>
                <AutocompleteSelect
                  id="tipo"
                  label="Tipo de relatório"
                  required={true}
                  isMultiple={false}
                  disabled={false}
                  data={dataType}
                  value={relForm.tipo}
                  onChange={(event) => setRelForm({ ...relForm, tipo: event ? event.id : null })}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={12} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={(e) => ExecuteReport(false)} >Executar</Button>
                </Tooltip>
              </Grid>

            </Grid>
          </AccordionDetails>
        </Accordion>

      </Grid>

      {reportContent}

      <ModalDialog 
        openModal={modalStatus} 
        title={modalTitle} 
        content={modalData} 
        onClose={() => setModalStatus(false)} 
        isFullscreen={false} 
        maxWidth={"lg"}
        fullWidth={true}
        dialogActionsContent={''}
      />

    </Grid>
  );
}

export default ReportActivities;