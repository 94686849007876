import * as symbols from './symbols';

export const REDIRECT_ROUTE_HOME = "/";
export const REDIRECT_ROUTE_LOGIN = "/login";

/* Permissions */
export const INVENTORY_PERMISSION_NAME = "estoque";
export const FINANCES_PERMISSION_NAME = 'financeiro';
export const CALCULATORS_PERMISSION_NAME = "calculadoras";
export const PROPERTIES_PERMISSION_NAME = "propriedades";
export const AGRONOMIC_PRESCRIPTION_PERMISSION_NAME = "receitas";
export const ACTIVITIES_PERMISSION_NAME = "registro_atividades_pulverizacao";

export const getOriginDestiny = (origem, destino, data) => {

  const tipoOrigemMap = {
    'FAZENDA': data.fazenda_origem_nome,
    'FILIAL': data.filial_origem_nome,
    'FORNECEDOR': data.fornecedor_nome,
    'USO': 'Campo',
  };

  const tipoDestinoMap = {
    'FAZENDA': data.fazenda_destino_nome,
    'FILIAL': data.filial_destino_nome,
    'FORNECEDOR': data.fornecedor_nome,
    'USO': 'Campo',
  };

  const origin = tipoOrigemMap[origem] || '';
  const destination = tipoDestinoMap[destino] || '';

  return { origin, destination };
};

export const getDevice = () => {
  let screenWidth = window.innerWidth;

  if (screenWidth >= 1000) {
    return 'desktop';
  } else if (screenWidth >= 600) {
    return 'tablet';
  } else {
    return 'mobile';
  }
};

export const getCurrentDate = (offsetMonths = 0, offsetDays = 0, tipo) => {
  const today = new Date();

  if (tipo === 'soma') {
    today.setDate(today.getDate() + offsetDays);
    today.setMonth(today.getMonth() + offsetMonths);
  } else {
    today.setDate(today.getDate() - offsetDays);
    today.setMonth(today.getMonth() - offsetMonths);
  }

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => { resolve(position); },
        (error) => { reject(error); }
      );
    } else {
      reject("A geolocalização não é suportada neste navegador.");
    }
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const randomString = () => {
  return (Math.random() + 1).toString(36).substring(7) + Math.random() + 1
}

export const WeatherIcon = ({ icon, width }) => {
  try {
    const iconPath = symbols[icon];
    if (iconPath) {
      return <img src={iconPath.default} alt="Weather Icon" width={width} />;
    }
  } catch (error) {
    console.error('Erro ao obter ícone', error);
    return null;
  }
};

export const ErrorToStringFix = (error) => {
  try {
    let c = error.replaceAll("'", "|||").replaceAll('"', "'").replaceAll("|||", '"')
    let json = JSON.parse(c)
    let key = Object.keys(json)[0]

    let mensagem = ''
    if (typeof (json[key]) === 'string') {
      mensagem = json[key]
    } else {
      mensagem = `${key}: ${json[key][0]}`
    }

    return mensagem
  } catch (e) {
    try {
      if (typeof (error) === 'string') {
        return error
      }

      if (Array.isArray(error)) {
        let n1 = error[0]

        if (typeof (n1) === 'string') {
          return n1
        }

        if (n1[0] === "__all__") {
          return n1[1]
        }

        return n1[0]
      }

      return ''
    } catch (e) {
      console.log(e)
      return 'Um erro ocorreu durante a execução e resposta do serviço, contate o suporte!'
    }
  }

}

export const getWeatherColor = (weatherDescription = '') => {
  const weatherColors = {
    clearsky_day: '#87CEEB',             // Céu limpo de dia - azul céu claro e vibrante
    clearsky_night: '#1B2A38',           // Céu limpo à noite - azul bem escuro
    clearsky_polartwilight: '#6A7D8C',   // Céu limpo no crepúsculo polar - cinza com toque azulado
    fair_day: '#87CEEB',                 // Bom tempo durante o dia - azul claro e vibrante
    fair_night: '#2D4050',               // Bom tempo à noite - azul escuro levemente acinzentado
    fair_polartwilight: '#7B8A98',       // Bom tempo no crepúsculo polar - cinza azulado
    partlycloudy_day: '#7FBBC4',         // Parcialmente nublado durante o dia - azul acinzentado mais vivo
    partlycloudy_night: '#3E5363',       // Parcialmente nublado à noite - cinza azulado escuro
    partlycloudy_polartwilight: '#6E7D89', // Parcialmente nublado no crepúsculo polar - cinza neutro com toque de azul
    cloudy: '#7A8B94',                   // Nublado - cinza moderado com tom frio
    rainshowers_day: '#5F8FA7',          // Chuva durante o dia - azul escuro com cinza
    rainshowers_night: '#2B3D4F',        // Chuva à noite - cinza escuro com azul
    rainshowers_polartwilight: '#506773', // Chuva no crepúsculo polar - cinza com azul moderado
    rain: '#406169',                     // Chuva forte - azul acinzentado profundo
    thunderstorm: '#303A45',             // Tempestade - cinza bem escuro com azul
    drizzle: '#8DA6B8',                  // Garoa - cinza claro com toque de azul
    snow: '#E8EEF1',                     // Neve - branco acinzentado muito claro
    fog: '#C1CBD3',                      // Névoa - cinza suave
    sleet: '#8998A4',                    // Granizo - cinza frio moderado
    default: '#87CEEB'                   // Padrão - azul claro e vibrante
  };

  const matchingKey = Object.keys(weatherColors).find(key => weatherDescription.toLowerCase().includes(key));

  return weatherColors[matchingKey] || weatherColors.default;
};

export const showLoading = show => {
  if (show) {
    document.getElementById("mascara").style.display = "initial";
  } else {
    document.getElementById("mascara").style.display = "none";
  }
}