import { Autocomplete, Card, CardContent, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import "../../../App.css";

import unidadesAgrupadas from "./ConversorUnits";

const Conversor = () => {
  const [title] = useState("Calculadora de Conversão de Medidas");
  const [formData, setFormData] = useState({
    valor: "",
    unidadeOrigem: null,
    unidadeDestino: null,
    resultado: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const converterUnidades = () => {
    const { valor, unidadeOrigem, unidadeDestino } = formData;
  
    if (!valor || !unidadeOrigem || !unidadeDestino || unidadeOrigem.tipo !== unidadeDestino.tipo) {
      return "";
    }
  
    const valorConvertido =
      (parseFloat(valor) * unidadeOrigem.indice_conversao) / unidadeDestino.indice_conversao;
    
    return parseFloat(valorConvertido.toFixed(5)).toLocaleString('pt-BR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 5,
    });
  };
  
  const unidadesFiltradas = formData.unidadeOrigem
    ? unidadesAgrupadas.filter((unidade) => unidade.tipo === formData.unidadeOrigem.tipo)
    : unidadesAgrupadas;

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Calculadoras - Conversão de Medidas</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} mt={2} textAlign="center">
        <Typography className="titles-pages" variant="h4">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>
            <TextField
              name="valor"
              label="Valor"
              type="number"
              value={formData.valor}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            <Autocomplete
              id="unidadeOrigem"
              options={unidadesAgrupadas}
              groupBy={(option) => option.tipo}
              getOptionLabel={(option) => option.nome}
              onChange={(event, newValue) => setFormData({ ...formData, unidadeOrigem: newValue, unidadeDestino: null })}
              renderInput={(params) => (
                <TextField {...params} label="Unidade de Origem" margin="normal" fullWidth />
              )}
            />

            <Autocomplete
              id="unidadeDestino"
              options={unidadesFiltradas}
              groupBy={(option) => option.tipo}
              getOptionLabel={(option) => option.nome}
              onChange={(event, newValue) => setFormData({ ...formData, unidadeDestino: newValue })}
              renderInput={(params) => (
                <TextField {...params} label="Unidade de Destino" margin="normal" fullWidth />
              )}
              disabled={!formData.unidadeOrigem}
            />

            <TextField
              id="resultado"
              label="Resultado"
              value={converterUnidades()}
              InputProps={{
                readOnly: true,
                disabled: true,
                endAdornment: formData.unidadeDestino ? (
                  <InputAdornment position="end">{formData.unidadeDestino.nome}</InputAdornment>
                ) : null,
              }}
              fullWidth
              margin="normal"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Conversor;